export const GQL_ENDPOINT = window.location.hostname.includes("tollgard.com")
  ? "https://tollgard-api.devlab.zone/api/graphql"
  : window.location.hostname.includes("webflow.io")
  ? "https://tollgard-dev.devlab.zone/api/graphql"
  : "https://tollgard-dev.devlab.zone/api/graphql";

export const S3_BASE_URL = window.location.hostname.includes("tollgard.com")
  ? "https://tollgard-prod.s3.amazonaws.com/"
  : window.location.hostname.includes("devlab.zone")
  ? "https://tollgard-staging.s3.amazonaws.com/"
  : "https://tollgard-dev.s3.amazonaws.com/";

export const PUBLIC_PATHS = {
  landingPage: "/",
  myProjectsPage: "/my-projects/list",
  projectDetailsPage: "/my-projects/view",
  contactUsPage: "/contact-us",
  productPage: "/products/(.*)",
};

export const CLIENT_URL = window.location.hostname.includes("tollgard.com") ? "https://www.tollgard.com" : "https://new-tollgard.webflow.io";

export const GUEST_PROJECTS_LOCALSTORAGE_KEY = "tg-prj-ls";
export const GUEST_ROOMS_LOCALSTORAGE_KEY = "tg-rooms-ls";

export const PLACEHOLDER_IMAGE = window.location.hostname.includes("tollgard.com")
  ? "https://cdn.prod.website-files.com/64ec87f3cc340318d54e715d/675a842a45f4df509f755f9a_DALL%C2%B7E%20Web%20Design%20Layout%20Dec%2011.webp"
  : "https://cdn.prod.website-files.com/64ec87f3cc340318d54e715d/675a842a45f4df509f755f9a_DALL%C2%B7E%20Web%20Design%20Layout%20Dec%2011.webp";
