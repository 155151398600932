// apps/client/src/modules/public/navbar/index.ts

import { navigate, WFComponent } from "@xatom/core";
import { publicAuth, setGuestAuthDetails } from "../../../auth/public";
import { setLoggedInUserNav, signIn, signUp, userForgotPassword, userResetPassword } from "../auth";
import { GUEST_PROJECTS_LOCALSTORAGE_KEY } from "../../../config";

export const navbar = () => {
  const myProjectsLink = new WFComponent(`[xa-type="my-projects-link"]`);
  // const myProjectsCounterLink = new WFComponent(`[xa-type="projects-counter-link"]`);
  // myProjectsCounterLink.addCssClass("hide");

  if (publicAuth?.getUser()?.email) {
    const isGuestUser = publicAuth.getUser().email === "guestuser@tollgard.test";
    if (isGuestUser) {
      const data = JSON.parse(localStorage.getItem(GUEST_PROJECTS_LOCALSTORAGE_KEY) || "[]");
      const navProjectCounter = new WFComponent(`[xa-type="nav-project-counter"]`);
      navProjectCounter.setText(data?.length || 0);
      signIn();
      signUp();
      userForgotPassword();
      // myProjectsCounterLink.addCssClass("hide");
      myProjectsLink.addCssClass("hide");
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get("page");
      if (page === "reset-password") {
        const resetPasswordLink = new WFComponent<HTMLAnchorElement>(`[xa-type="reset-password-trigger"]`);
        resetPasswordLink.getElement().click();
        userResetPassword();
      }
      myProjectsLink.removeCssClass("hide");
    } else {
      setLoggedInUserNav(publicAuth.getUser().name);
      myProjectsLink.removeCssClass("hide");
    }
  } else {
    loginGuestUser();
    signIn();
    signUp();
    userForgotPassword();
    // myProjectsCounterLink.addCssClass("hide");
    myProjectsLink.addCssClass("hide");
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get("page");
    if (page === "reset-password") {
      const resetPasswordLink = new WFComponent<HTMLAnchorElement>(`[xa-type="reset-password-trigger"]`);
      resetPasswordLink.getElement().click();
      userResetPassword();
    }
  }
};

export const loginGuestUser = () => {
  setGuestAuthDetails(`Guest User`);
  setLoggedInUserNav(`Guest User`);
  const myProjectsLink = new WFComponent(`[xa-type="my-projects-link"]`);
  myProjectsLink.removeCssClass("hide");
};
