import { loadAdminAuthFromStorage } from "./auth/admin";
import { loadPublicAuthFromStorage } from "./auth/public";
import { publicRoutes } from "./routes/public";

const app = () => {
  //auth
  loadPublicAuthFromStorage();

  //routes
  publicRoutes();
};

export default app;

