import { WFRoute} from "@xatom/core";
import { PUBLIC_PATHS } from "../../config";
import { navbar } from "../../modules/public/navbar";
import { newsletter } from "../../modules/public/newsletter";

export const publicRoutes = () => {
    newsletter();
    navbar();
    new WFRoute(PUBLIC_PATHS.landingPage).execute(() => import("../../modules/public/landing-page").then(({landingPage}) => {landingPage()}));
    new WFRoute(PUBLIC_PATHS.contactUsPage).execute(() => import("../../modules/public/contact-us").then(({contactUsPage}) => {contactUsPage()}));
    new WFRoute(PUBLIC_PATHS.myProjectsPage).execute(() => import("../../modules/public/my-projects").then(({myProjects}) => {myProjects()}));
    new WFRoute(PUBLIC_PATHS.projectDetailsPage).execute((params: any) => import("../../modules/public/project-details").then(({projectDetails}) => {projectDetails(params)}));
    new WFRoute(PUBLIC_PATHS.productPage).execute((params: any) => import("../../modules/public/product-page").then(({productPage}) => {productPage()}));
}