import { WFComponent, WFFormComponent, navigate } from "@xatom/core";
import { logoutPublicAuth, publicAuth, setGuestAuthDetails, setPublicAuthDetails } from "../../../auth/public";
import { publicQL } from "../../../graphql";
import {
  AddNewProjectDocument,
  AddToProjectDocument,
  GetProjectsCountDocument,
  PublicForgotPasswordDocument,
  PublicLoginDocument,
  PublicResetPasswordDocument,
  PublicSignupDocument,
} from "../../../graphql/graphql";
import { CLIENT_URL, GUEST_PROJECTS_LOCALSTORAGE_KEY, PUBLIC_PATHS } from "../../../config";

export const signIn = () => {
  const publicLoginReq = publicQL.mutation(PublicLoginDocument);
  const signInForm = new WFFormComponent<{
    Email: string;
    Password: string;
  }>(`[xa-type="signin-form"]`);
  let email = "";
  publicLoginReq.onData(async (data) => {
    const isGuestUser = publicAuth.getUser().email === "guestuser@tollgard.test";
    setPublicAuthDetails(`${data.publicLogin.firstName} ${data.publicLogin.lastName}`, email, data.publicLogin.token);
    setLoggedInUserNav(`${data.publicLogin.firstName} ${data.publicLogin.lastName}`);
    if (isGuestUser) {
      signInForm.updateSubmitButtonText("Saving your projects...");
      await saveGuestUserProjects();
      signInForm.updateSubmitButtonText("Sign In");
    }
    navigate("/my-projects/list");
  });

  publicLoginReq.onLoadingChange((status) => {
    if (status) signInForm.disableForm();
    else signInForm.enableForm();

    signInForm.updateSubmitButtonText(status ? "Please wait.." : "Sign In");
  });

  publicLoginReq.onError((err) => {
    const errorMessageDiv = new WFComponent(`[aria-label="Sign In Form failure"]`);
    errorMessageDiv.setText(err.message || "Something went wrong!");
    signInForm.showErrorState();
  });

  signInForm.onFormSubmit((data) => {
    email = data["Email"];
    publicLoginReq.fetch({
      email: data["Email"],
      password: data["Password"],
    });
  });
};

export const setLoggedInUserNav = (fullName: string) => {
  const isGuestUser = publicAuth.getUser().email === "guestuser@tollgard.test";
  const navProjectCounter = new WFComponent(`[xa-type="nav-project-counter"]`);
  const getProjectCountReq = publicQL.query(GetProjectsCountDocument);
  if (!isGuestUser) {
    getProjectCountReq.onData((data) => {
      navProjectCounter.setText(data.getProjectsCount || 0);
    });
    getProjectCountReq.fetch();
    const navDropdown = document.getElementsByClassName("dropdown_list")[0];
    navDropdown.innerHTML = "";
    const userLink = document.createElement("a");
    userLink.className = "dropdown_link w-inline-block";
    userLink.href = "#";
    userLink.tabIndex = 0;
    const innerDiv = document.createElement("div");
    innerDiv.className = "text-size-medium";
    innerDiv.innerText = `Hi, ${fullName}`;
    userLink.appendChild(innerDiv);
    navDropdown.appendChild(userLink);
    const userLogoutLink = document.createElement("a");
    userLogoutLink.className = "dropdown_link w-inline-block";
    userLogoutLink.href = "#";
    userLogoutLink.onclick = (e) => {
      e.stopPropagation();
      e.preventDefault();
      logoutPublicAuth();

      loginGuestUser();
    };
    userLogoutLink.tabIndex = 0;
    const logoutInnerDiv = document.createElement("div");
    logoutInnerDiv.className = "text-size-medium";
    logoutInnerDiv.innerText = `Logout`;
    userLogoutLink.appendChild(logoutInnerDiv);
    navDropdown.appendChild(userLogoutLink);
  }
};

export const signUp = () => {
  const publicSignupReq = publicQL.mutation(PublicSignupDocument);
  const signupForm = new WFFormComponent<{
    "Signup-Email-2": string;
    "Password-3": string;
    "First-Name-2": string;
    "Last-Name-2": string;
    "radio-user-type": string;
  }>(`[xa-type="signup-form"]`);
  let email = "";
  publicSignupReq.onData(async (data) => {
    const isGuestUser = publicAuth.getUser().email === "guestuser@tollgard.test";
    setPublicAuthDetails(`${data.publicSignup.firstName} ${data.publicSignup.lastName}`, email, data.publicSignup.token);
    setLoggedInUserNav(`${data.publicSignup.firstName} ${data.publicSignup.lastName}`);
    if (isGuestUser) {
      signupForm.updateSubmitButtonText("Saving your projects...");
      await saveGuestUserProjects();
      signupForm.updateSubmitButtonText("Register");
    }
    navigate("/my-projects/list");
  });

  publicSignupReq.onLoadingChange((status) => {
    if (status) signupForm.disableForm();
    else signupForm.enableForm();

    signupForm.updateSubmitButtonText(status ? "Please wait.." : "Register");
  });

  publicSignupReq.onError((err) => {
    const errorMessageDiv = new WFComponent(`[aria-label="Sign Up Form failure"]`);
    errorMessageDiv.getChildAsComponent("div").setText(err.message || "Something went wrong!");
    signupForm.showErrorState();
  });

  signupForm.onFormSubmit((data) => {
    email = data["Signup-Email-2"];
    publicSignupReq.fetch({
      email: data["Signup-Email-2"],
      password: data["Password-3"],
      firstName: data["First-Name-2"],
      lastName: data["Last-Name-2"],
      userType: data["radio-user-type"] ? data["radio-user-type"].toUpperCase() : "",
    });
  });
};

export const userForgotPassword = () => {
  const forgotPasswordReq = publicQL.mutation(PublicForgotPasswordDocument);
  const forgotPasswordForm = new WFFormComponent<{
    Email: string;
  }>(`[xa-type="forgot-pass-form"]`);
  let email = "";

  forgotPasswordForm.onFormSubmit((data) => {
    email = data["Email-4"];
    forgotPasswordReq.fetch({
      email: data["Email-4"],
    });
  });
  forgotPasswordReq.onLoadingChange((status) => {
    if (status) forgotPasswordForm.disableForm();
    else forgotPasswordForm.enableForm();

    forgotPasswordForm.updateSubmitButtonText(status ? "Please wait.." : "Continue");
  });
  forgotPasswordReq.onError((err) => {
    const errorMessageDiv = new WFComponent(`[aria-label="Email Form failure"]`);
    errorMessageDiv.getChildAsComponent("div").setText(err.message || "Something went wrong!");
    forgotPasswordForm.showErrorState();
  });
  forgotPasswordReq.onData((data) => {
    setTimeout(() => {
      const successMessageDiv = new WFComponent(`[aria-label="sidebar success"]`);
      successMessageDiv.getChildAsComponent("div").setText("Password reset link has been sent to your email.");
      forgotPasswordForm.disableForm();
      forgotPasswordForm.updateSubmitButtonText("Redirecting...");
      forgotPasswordForm.showSuccessState();
    }, 100);
    setTimeout(() => {
      navigate(PUBLIC_PATHS.landingPage);
    }, 3000);
  });
};

export const userResetPassword = () => {
  const resetPasswordReq = publicQL.mutation(PublicResetPasswordDocument);
  const resetPasswordForm = new WFFormComponent<{
    "new-password-2": string;
    "confirm-password-2": string;
  }>(`[xa-type="reset-pass-form"]`);
  let email = "";
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  resetPasswordForm.onFormSubmit((data) => {
    email = data["user-email"];
    if (data["new-password-2"] === data["confirm-password-2"]) {
      resetPasswordReq.fetch({
        newPassword: data["new-password-2"],
        token,
      });
    } else {
      const errorMessageDiv = new WFComponent(`[aria-label="Email Form failure"]`);
      errorMessageDiv.getChildAsComponent("div").setText("Passwords are not matching!");
      resetPasswordForm.showErrorState();
    }
  });
  resetPasswordReq.onLoadingChange((status) => {
    if (status) resetPasswordForm.disableForm();
    else resetPasswordForm.enableForm();

    resetPasswordForm.updateSubmitButtonText(status ? "Please wait.." : "Reset Password");
  });
  resetPasswordReq.onError((err) => {
    const errorMessageDiv = new WFComponent(`[aria-label="Email Form failure"]`);
    errorMessageDiv.getChildAsComponent("div").setText(err.message || "Something went wrong!");
    resetPasswordForm.showErrorState();
  });
  resetPasswordReq.onData((data) => {
    setTimeout(() => {
      resetPasswordForm.disableForm();
      resetPasswordForm.updateSubmitButtonText("Redirecting...");
    }, 100);
    setTimeout(() => {
      window.location.href = CLIENT_URL;
    }, 2000);
  });
};

export const loginGuestUser = () => {
  setGuestAuthDetails(`Guest User`);
  setLoggedInUserNav(`Guest User`);
  const myProjectsLink = new WFComponent(`[xa-type="my-projects-link"]`);
  myProjectsLink.removeCssClass("hide");
};

export const saveGuestUserProjects = () => {
  return new Promise((resolve, reject) => {
    const listData = JSON.parse(localStorage.getItem(GUEST_PROJECTS_LOCALSTORAGE_KEY) || "[]");
    let totalNumberOfProducts = 0;
    for (let proj of listData) {
      totalNumberOfProducts += proj.products.length;
    }
    if (listData?.length) {
      const createNewProjectReq = publicQL.mutation(AddNewProjectDocument);
      const addToProjectReq = publicQL.mutation(AddToProjectDocument);
      let addedCount = 0;
      createNewProjectReq.onData((data) => {
        if (data?.addNewProject) {
          const _project = listData.find((p) => p.projectName === data.addNewProject.projectName);
          addToProjectReq.onData((pproductData) => {
            if (pproductData.addToProject) {
              addedCount++;
            }
            if (totalNumberOfProducts === addedCount) {
              localStorage.removeItem(GUEST_PROJECTS_LOCALSTORAGE_KEY);
              resolve(true);
            }
          });
          addToProjectReq.onError(reject);
          Array(..._project.products).forEach((p) => {
            addToProjectReq.fetch({
              brand: p.brand,
              leadTime: p.leadTime,
              name: p.name,
              notes: p.notes,
              price: p.price || 0,
              salePrice: p.salePrice || 0,
              productSlug: p.productSlug,
              projectId: data.addNewProject.id,
              qty: p.qty,
              thumbnail: p.thumbnail,
              category: p.category || "Uncategorized",
            });
          });
        }
      });
      listData.forEach((project) => {
        createNewProjectReq.onError(reject);
        createNewProjectReq.fetch({
          name: project.projectName,
        });
      });
    } else {
      resolve(true);
    }
  });
};
