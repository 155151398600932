import { WFComponent, WFFormComponent } from "@xatom/core";
import { publicQL } from "../../../graphql";
import { SubscribeNewsletterDocument } from "../../../graphql/graphql";

export const newsletter = () => {
    const newsLetterSubscriptionReq = publicQL.mutation(SubscribeNewsletterDocument);
    const closeBtn = new WFComponent(`[xa-type="close-btn"]`);
    const newsLetterForm = new WFFormComponent<{
        "newsletter-name": string
        "newsletter-email": string
    }>(`[xa-type="newletter-form"]`);

    newsLetterSubscriptionReq.onData((res) => {
        setTimeout(() => {
            closeBtn.getElement().click();
            newsLetterForm.resetForm();
        }, 3000);
        if(res) {
            newsLetterForm.showSuccessState();
        } else {
            newsLetterForm.showErrorState();
        }
    });

    newsLetterSubscriptionReq.onError((err) => {
        setTimeout(() => {
            newsLetterForm.resetForm();
        }, 3000);
        newsLetterForm.showErrorState();
    });

    newsLetterForm.onFormSubmit((data) => {
        newsLetterSubscriptionReq.fetch({
            email: data["newsletter-email"],
            name: data["newsletter-name"]
        });
    });
}